import { css } from "aphrodite";
import { memo, useState, useCallback } from "react";
import { Transition } from "react-transition-group";

import Tabs from "components/Common/Tabs";
import HeaderSearch from "components/Layout/Header/HeaderSearch";

import modalActions from "../../actions/modals";
import BasicTooltip from "../../components/Common/Tooltip/BasicTooltip";
import useActionCreators from "../../hooks/useActionCreators";
import rawTokens from "../../styles/tokens/tokens-raw";
import HomepageBackgroundLeft from "./Images/HomepageBackgroundLeft.png";
import HomepageBackgroundRight from "./Images/HomepageBackgroundRight.png";

import sendGAEvent from "utils/sendGAEvent";

import { useStyles } from "hooks/useStyles";
import useWindowSize from "hooks/useWindowSize";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";
import ScreenSizes, { createBreakpoint } from "styles/ScreenSizes";

const baseStyles = {
  container: {
    color: colours.white,
    background: "linear-gradient(136.47deg, #350162 2.45%, #6B06A6 100%)",

    position: "relative",
    paddingTop: 0,

    [ScreenSizes.lgAndAbove]: {
      paddingTop: 120,
    },

    [createBreakpoint({ min: 1400 })]: {
      paddingTop: 54,
    },
  },
  searchWrapper: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",

    padding: "0 2rem 14rem",

    [ScreenSizes.lgAndAbove]: {
      paddingBottom: "21rem",
    },
  },
  title: {
    ...gStyles.avalonBold,
    fontSize: "2rem",
    textAlign: "center",
    margin: 0,
    marginTop: "3.5rem",
    marginBottom: "1rem",
    lineHeight: 1.1,

    [ScreenSizes.lgAndAbove]: {
      marginTop: "5rem",
      fontSize: "3rem",
      maxWidth: "85rem",
    },
    [ScreenSizes.xxlAndAbove]: {
      fontSize: "4.5rem",
    },
  },
  secondaryTitle: {
    display: "block",
    fontSize: "1em",
    textAlign: "center",
    background:
      "linear-gradient(90deg, #C600FF 0%, #A003ED 55.11%, #6801BD 100%)",

    "-webkit-background-clip": "text",
    "-webkit-text-fill-color": "transparent",
  },
  secondaryTitleInline: {
    display: "inline",
  },
  show: {
    display: "block",
  },
  hide: {
    display: "none",
    opacity: 0,
  },
  subTitle: {
    ...gStyles.fontRegular,
    fontSize: "1rem",
    textAlign: "center",
    maxWidth: "50rem",
    margin: "0 auto",
    padding: "0 0.5rem",
    lineHeight: "2rem",

    [ScreenSizes.lgAndAbove]: {
      fontSize: "1.25rem",
    },
  },
  tabsContainer: {
    zIndex: 1,

    display: "inline-flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "0.75rem",

    width: "100%",
    padding: "1rem 0",
    marginBottom: "1rem",
    marginTop: "1rem",

    [ScreenSizes.lgAndAbove]: {
      minWidth: "45rem",

      gap: "1.5rem",

      padding: "2rem",
      marginTop: "2rem",
    },
  },
  tabsSelectorContainer: {
    display: "inline-flex",
    alignItems: "flex-start",
    gap: "2rem",
    margin: 0,
  },
  backgroundLeftImage: {
    backgroundImage: `url(${HomepageBackgroundLeft})`,

    position: "absolute",
    top: 0,

    width: "50%",
    height: "100%",

    clipPath: "polygon(0 0, 0 76%, 100% 90%, 100% 0)",
  },
  backgroundRightImage: {
    backgroundImage: `url(${HomepageBackgroundRight})`,
    backgroundPosition: "right top",

    position: "absolute",
    right: 0,
    top: 0,

    width: "50%",
    height: "100%",

    clipPath: "polygon(0 0, 0 90%, 100% 100%, 100% 0)",
  },
  proTab: {
    display: "inline-flex",
    marginLeft: "0.5rem",
  },
};

export const TABS_KEYS = {
  ALL: "ALL",
  PODCASTS: "PODCASTS",
  EPISODES: "EPISODES",
  CREDITS: "CREDITS",
  LISTS: "LISTS",
  BRANDS: "BRANDS",
};

const TABS_MOBILE = [
  {
    key: TABS_KEYS.ALL,
    title: "All",
    componentType: "button",
  },
  {
    key: TABS_KEYS.PODCASTS,
    title: "Podcasts",
    componentType: "button",
  },
  {
    key: TABS_KEYS.CREDITS,
    title: "Credits",
    componentType: "button",
  },
  {
    key: TABS_KEYS.LISTS,
    title: "Lists",
    componentType: "button",
  },
];

const TAB_STYLES = {
  tab: {
    ...gStyles.avalonBold,
    display: "inline-flex",
    flexDirection: "row",
    alignItems: "center",
    paddingBottom: 10,
    borderBottom: "2px transparent solid",
    cursor: "pointer",

    color: rawTokens.raw.color.violet.l90,
    fontSize: "1rem",
    lineHeight: "1.5rem",
    border: "none",
  },
  currentTab: {
    paddingBottom: 10,
    borderBottom: `4px ${colours.primary} solid`,
    color: colours.white,
  },
  tabContainer: {
    padding: "0 0.375rem",
  },
  tabs: {
    marginBottom: 0,
  },
};

const headerSearchOverride = {
  headerSearch: {
    width: "100%",

    [ScreenSizes.mdAndBelow]: {
      background: "none",
      position: "static",
      paddingLeft: 0,
      paddingRight: 0,
      left: 0,
      top: 0,
      margin: 0,
      width: "100%",
      maxWidth: "100%",
      transform: "none",
    },

    [ScreenSizes.xlAndAbove]: {
      minWidth: "100%",
    },
  },
  showHeaderSearch: {
    [ScreenSizes.mdAndBelow]: {
      transform: "none",
      position: "static",
    },
  },
};

const SEARCH_AUTO_COMPLETE_STYLES = {
  container: {
    zIndex: 1,
    transition: "none",

    height: "3.75rem",

    [ScreenSizes.lgAndAbove]: {
      transition: "none",
      maxWidth: "47rem",
      minWidth: "17rem",

      height: "3.75rem",
      maxHeight: "3.75rem",
    },

    [ScreenSizes.xlAndAbove]: {
      minWidth: "24.375rem",
    },

    [createBreakpoint({ min: 1400 })]: {
      marginLeft: 0,
    },
  },
  search: {
    borderRadius: 30,

    [ScreenSizes.lgAndAbove]: {
      borderRadius: 40,
    },
  },
  searchFocus: {
    borderRadius: 30,

    [ScreenSizes.lgAndAbove]: {
      borderRadius: 40,
    },
  },
};

const duration = 350;

const defaultStyle = {
  transition: `opacity ${duration}ms ease-in-out`,
  opacity: 0,
  height: 0,
};

const transitionStyles = {
  entering: { opacity: 0 },
  entered: { opacity: 1, height: "auto" },
  exiting: { opacity: 0, height: "auto" },
  exited: { opacity: 0 },
};

const HomepageDesktopHeader = (props) => {
  const { styles } = useStyles(baseStyles, props);

  const windowSize = useWindowSize();
  const isMobileAndTablet = windowSize.isWindowSizeOrLess("medium");

  const [tab, setTab] = useState(TABS_KEYS.ALL);

  const { showModal } = useActionCreators({
    showModal: modalActions.showModal,
  });

  const onDemoRequestSubmitted = useCallback(() => {
    sendGAEvent({
      action: `submittedProLeadFormModal`,
      page: "Homepage",
      context: "ProLeadForm",
    });
  }, []);

  const handleProButtonClick = useCallback(
    (e) => {
      e.stopPropagation();
      e.preventDefault();
      sendGAEvent({
        action: `openedProLeadForm`,
        page: `Homepage`,
        context: "ProLeadForm",
      });
      showModal("proLeadForm", {
        onDemoRequestSubmitted: onDemoRequestSubmitted,
      });
    },
    [onDemoRequestSubmitted, showModal]
  );

  const handleFocus = useCallback(() => {
    sendGAEvent({
      action: "searchFocus",
      page: "LoggedOutHomepage",
    });
  }, []);

  const handleSearchChange = useCallback((term) => {
    sendGAEvent({
      action: "searchChange",
      term,
      page: "LoggedOutHomepage",
    });
  }, []);

  const handleTabChange = useCallback((tab) => {
    setTab(tab.key);

    sendGAEvent({
      action: "headerTabClick",
      context: "HomePage",
      page: "LoggedOutHomepage",
      tab: tab.key,
    });
  }, []);

  const TABS = [
    {
      key: TABS_KEYS.ALL,
      title: "All",
      componentType: "button",
    },
    {
      key: TABS_KEYS.PODCASTS,
      title: "Podcasts",
      componentType: "button",
    },
    {
      key: TABS_KEYS.EPISODES,
      title: "Episodes",
      componentType: "button",
    },
    {
      key: TABS_KEYS.CREDITS,
      title: "Credits",
      componentType: "button",
    },
    {
      key: TABS_KEYS.LISTS,
      title: "Lists",
      componentType: "button",
    },
    {
      key: TABS_KEYS.BRANDS,
      title: "Brands",
      anchor: "/brands",
      onClick: (e) => {
        sendGAEvent({
          action: "headerTabClick",
          context: "HomePage",
          tab: TABS_KEYS.BRANDS,
        });
        return handleProButtonClick(e);
      },
      pro: true,
      proLinked: false,
      renderTab: ({ defaultRenderTab }) => {
        return (
          <BasicTooltip
            renderTooltip={() => "Try Pro to access brands and more"}
          >
            {(tooltipProps) => (
              <div className={css(styles.proTab)} {...tooltipProps}>
                {defaultRenderTab()}
              </div>
            )}
          </BasicTooltip>
        );
      },
    },
  ];

  return (
    <div className={css(styles.container)}>
      <div className={css(styles.backgroundLeftImage)} />
      <div className={css(styles.backgroundRightImage)} />
      <div className={css(styles.searchWrapper)}>
        <Transition in={tab === TABS_KEYS.ALL} timeout={duration}>
          {(state) => (
            <div
              style={{
                ...defaultStyle,
                ...transitionStyles[state],
              }}
            >
              <h1 className={css(styles.title)}>
                Find Podcasts. Access Audiences.
                <span className={css(styles.secondaryTitle)}>
                  Drive Results.
                </span>
              </h1>
              <h2 className={css(styles.subTitle)}>
                Podchaser is the world’s best podcast database and search engine
                – powering discovery for listeners, creators, brands, and
                agencies.
              </h2>
            </div>
          )}
        </Transition>

        <Transition in={tab === TABS_KEYS.PODCASTS} timeout={duration}>
          {(state) => (
            <div
              style={{
                ...defaultStyle,
                ...transitionStyles[state],
              }}
            >
              <h1 className={css(styles.title)}>
                Search and browse over
                <span className={css(styles.secondaryTitle)}>
                  5 million podcasts
                </span>
              </h1>
              <h2 className={css(styles.subTitle)}>
                Explore the world’s largest selection of podcasts by categories,
                demographics, ratings, reviews, host and guest credits, and
                more.
              </h2>
            </div>
          )}
        </Transition>

        <Transition in={tab === TABS_KEYS.EPISODES} timeout={duration}>
          {(state) => (
            <div
              style={{
                ...defaultStyle,
                ...transitionStyles[state],
              }}
            >
              <h1 className={css(styles.title)}>
                Discover episodes on your{" "}
                <span className={css(styles.secondaryTitle)}>
                  favorite topics
                </span>
              </h1>
              <h2 className={css(styles.subTitle)}>
                Explore a world of topics in every episode. Search hundreds of
                millions of podcast episodes — keep up with the latest news,
                follow your interests, or learn all about a new subject.
              </h2>
            </div>
          )}
        </Transition>

        <Transition in={tab === TABS_KEYS.CREDITS} timeout={duration}>
          {(state) => (
            <div
              style={{
                ...defaultStyle,
                ...transitionStyles[state],
              }}
            >
              <h1 className={css(styles.title)}>
                Meet{" "}
                <span
                  className={css(
                    styles.secondaryTitle,
                    styles.secondaryTitleInline
                  )}
                >
                  the people
                </span>{" "}
                behind your favorite podcasts
              </h1>
              <h2 className={css(styles.subTitle)}>
                Just like movie credits, podcast credits let you browse and keep
                track of each podcast’s hosts, guests, voice actors, and crew.
              </h2>
            </div>
          )}
        </Transition>

        <Transition in={tab === TABS_KEYS.LISTS} timeout={duration}>
          {(state) => (
            <div
              style={{
                ...defaultStyle,
                ...transitionStyles[state],
              }}
            >
              <h1 className={css(styles.title)}>
                <span className={css(styles.secondaryTitle)}>
                  Podcast playlists
                </span>{" "}
                handpicked by real people
              </h1>
              <h2 className={css(styles.subTitle)}>
                Discover lists of podcasts and episodes curated by the Podchaser
                community or build your own to share with friends and
                colleagues.
              </h2>
            </div>
          )}
        </Transition>

        <Transition in={tab === TABS_KEYS.BRANDS} timeout={duration}>
          {(state) => (
            <div
              style={{
                ...defaultStyle,
                ...transitionStyles[state],
              }}
            >
              <h1 className={css(styles.title)}>
                Boost your next campaign with{" "}
                <span className={css(styles.secondaryTitle)}>
                  podcast sponsors and advertiser tracking
                </span>
              </h1>
              <h2 className={css(styles.subTitle)}>
                Keep tabs on how brands invest in podcasts using Podchaser's
                unique tools for ad tracking, spend data, airchecks, and more.
              </h2>
            </div>
          )}
        </Transition>

        <div className={css(styles.tabsContainer)}>
          <div className={css(styles.tabsSelectorContainer)}>
            <Tabs
              tabs={isMobileAndTablet ? TABS_MOBILE : TABS}
              tabKey={tab}
              onTabChange={handleTabChange}
              styles={TAB_STYLES}
            />
          </div>

          <HeaderSearch
            showTip={!isMobileAndTablet}
            styles={headerSearchOverride}
            switchToWhiteStyles
            onFocus={handleFocus}
            onSearchChange={handleSearchChange}
            searchAutocompleteStyles={SEARCH_AUTO_COMPLETE_STYLES}
            {...(tab === TABS_KEYS.PODCASTS
              ? {
                  searchHref: "/search/podcasts/q/",
                  placeholder: "Search podcasts...",
                }
              : {})}
            {...(tab === TABS_KEYS.EPISODES
              ? {
                  searchHref: "/search/episodes/q/",
                  placeholder: "Search episodes...",
                }
              : {})}
            {...(tab === TABS_KEYS.CREDITS
              ? {
                  searchHref: "/search/creators/q/",
                  placeholder: "Search creators...",
                }
              : {})}
            {...(tab === TABS_KEYS.LISTS
              ? {
                  searchHref: "/search/lists/q/",
                  placeholder: "Search lists...",
                }
              : {})}
          />
        </div>
      </div>
    </div>
  );
};

export default memo(HomepageDesktopHeader);
